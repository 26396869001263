import axios from "axios"

const baseURL = `${process.env.REACT_APP_BASE_API}`

export const searchTicker = async (queryStr) => {
  return axios.get(`${baseURL}/api/v1/search?${queryStr}`).catch((err) => {
    throw new Error(err)
  })
}

export const getTickerHistoricalPrice = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/historical-price/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getForexPrice = async (exchangeId) => {
  return axios
    .get(`${baseURL}/api/v1/forex-price/${exchangeId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getVisualizationData = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/stock-visualizer/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const signIn = async (reqBody) => {
  return axios.post(`${baseURL}/api/v1/users/signin`, reqBody).catch((err) => {
    throw new Error(err)
  })
}

export const signUp = async (reqBody, type = null) => {
  let path = `${baseURL}/api/v1/users/signup`
  if (type) path = `${path}/${type}`
  return axios
    .post(path, reqBody)
    .then((resp) => {
      return resp
    })
    .catch((err) => {
      if (err.response.data) return err?.response || {}
      throw new Error(err)
    })
}

export const getPolicies = async (type) => {
  return axios.get(`${baseURL}/api/v1/cms/${type}`).catch((err) => {
    throw new Error(err)
  })
}

export const getStrapiTerms = async () => {
  return axios.get(`${baseURL}/api/v1/cms/dictionary`).catch((err) => {
    throw new Error(err)
  })
}

export const getQuotes = async () => {
  return axios.get(`${baseURL}/api/v1/cms/quotes`).catch((err) => {
    throw new Error(err)
  })
}

export const getBooks = async () => {
  return axios.get(`${baseURL}/api/v1/cms/books`).catch((err) => {
    throw new Error(err)
  })
}

export const findStocks = async (reqBody) => {
  return axios
    .post(`${baseURL}/api/v1/stocks`, reqBody, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export const getWatchlists = async (userId) => {
  return axios.get(`${baseURL}/api/v1/watchlists/${userId}`).catch((err) => {
    throw new Error(err)
  })
}

export const createWatchlist = async (reqBody) => {
  return axios
    .post(`${baseURL}/api/v1/watchlists`, reqBody, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export const getVersion = async () => {
  return axios.get(`${baseURL}/api/v1/version`).catch((err) => {
    throw new Error(err)
  })
}
