import React from "react"
import { Chip } from "@mui/material"

const TickerChip = ({ ticker }) => {
  // Hash function to generate consistent numbers for each ticker
  const hashCode = (str) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }

  // Convert hash to a color
  const stringToColor = (str) => {
    const hash = hashCode(str)
    const hue = Math.abs(hash) % 360 // Hue (0-360)
    const saturation = 50 + (Math.abs(hash) % 50) // Saturation (50-100)
    const lightness = 40 + (Math.abs(hash) % 20) // Lightness (40-60)
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`
  }

  const color = stringToColor(ticker)

  return (
    <Chip
      label={ticker}
      size={"small"}
      sx={{
        backgroundColor: color,
        color: "white", // Ensure text contrast
        fontSize: "0.75rem",
        fontWeight: "bold",
        borderRadius: "4px", // Reduced border radius
        width: "75px", // Fixed width to accommodate up to 5 characters
        height: "24px", // Adjust height for alignment with fixed width
        textAlign: "center", // Center text horizontally
        justifyContent: "center", // Center text inside Chip
        overflow: "hidden", // Hide text overflow
        whiteSpace: "nowrap", // Prevent text wrapping
        padding: 0, // Remove additional padding
      }}
    />
  )
}

export default TickerChip
