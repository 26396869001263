export const allSectors = [
  { label: "Technology", value: "Technology" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Financials", value: "Financials" },
  { label: "Conglomerates", value: "Conglomerates" },
  { label: "Consumer Cyclical", value: "Consumer Cyclical" },
  { label: "Industrials", value: "Industrials" },
  { label: "Industrial Goods", value: "Industrial Goods" },
  { label: "Energy", value: "Energy" },
  { label: "Basic Materials", value: "Basic Materials" },
  { label: "Communication Services", value: "Communication Services" },
  { label: "Consumer Defensive", value: "Consumer Defensive" },
  { label: "Consumer Goods", value: "Consumer Goods" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Services", value: "Services" },
  { label: "Utilities", value: "Utilities" },
].sort((sectorA, sectorB) => {
  // Compare labels alphabetically (case-insensitive)
  const labelA = sectorA.label.toLowerCase()
  const labelB = sectorB.label.toLowerCase()
  if (labelA < labelB) {
    return -1 // A comes before B
  } else if (labelA > labelB) {
    return 1 // B comes before A
  } else {
    return 0 // Equal labels
  }
})

export const allClassifications = [
  { label: "Mega-cap", value: "Mega-cap" },
  { label: "Large-cap", value: "Large-cap" },
  { label: "Mid-cap", value: "Mid-cap" },
  { label: "Small-cap", value: "Small-cap" },
  { label: "Micro-cap", value: "Micro-cap" },
  { label: "Nano-cap", value: "Nano-cap" },
]

export const metricsForScreener = [
  {
    header: "Key Insights",
    options: [
      { label: "Rating", value: "overallScore" },
      { label: "MOAT", value: "moat.score" },
      { label: "Quality", value: "businessQuality.score" },
      { label: "Growth", value: "growthRates.score" },
      {
        label: "Management Effectiveness",
        value: "managementEffectiveness.score",
      },
      { label: "Financial Health", value: "financialHealth.score" },
      { label: "Margins", value: "profitability.score" },
      { label: "Valuation", value: "valuationMultiples.score" },
      { label: "Dividend", value: "dividend.score" },
    ],
  },
  {
    header: "Growth Rates - (CAGR)",
    options: [
      {
        label: "Revenue growth",
        value: "growthRates.revenue",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Operating Income growth",
        value: "growthRates.operatingIncome",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Net Income growth",
        value: "growthRates.netIncome",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Earning per share (EPS) growth",
        value: "growthRates.eps",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Book value per share (BVPS) growth",
        value: "growthRates.bvps",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Operating cashflow growth",
        value: "growthRates.operatingCashFlow",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Free cashflow growth",
        value: "growthRates.freeCashFlow",
        type: "Single",
        suffix: "%",
      },
    ],
  },
  {
    header: "Management Effectiveness",
    options: [
      {
        label: "Return on assets (ROA)",
        value: "managementEffectiveness.returnOnAssets",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Return on equity (ROE)",
        value: "managementEffectiveness.returnOnEquity",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Return on tangible equity (ROTE)",
        value: "managementEffectiveness.returnOnTangibleEquity",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Return on capital employed (ROCE)",
        value: "managementEffectiveness.returnOnCapitalEmployed",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Share buyback (repurchase)",
        value: "managementEffectiveness.shareBuyBack",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Return on retained earnings (RORE)",
        value: "managementEffectiveness.returnOnRetainedEarnings",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Return on incremental capital employed (ROICE)",
        value: "managementEffectiveness.returnOnIncrementalCapitalEmployed",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Stock based compensation (SBC) as percentage of FCF",
        value:
          "managementEffectiveness.stockBasedCompensationAsPercentageOfFCF",
        type: "Single",
        suffix: "%",
      },
    ],
  },
  {
    header: "Financial Health",
    options: [
      {
        label: "Current ratio",
        value: "financialHealth.currentRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Quick ratio",
        value: "financialHealth.quickRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Debt to equity (D/E) ratio",
        value: "financialHealth.debtEquityRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Interest coverage",
        value: "financialHealth.interestCoverage",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Debt to cashflow ratio",
        value: "financialHealth.debtToCashflowRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Goodwill and intangibles of total assets",
        value: "financialHealth.goodwillAndIntangibleAssetsToTotalAssets",
        type: "Single",
        suffix: "x",
      },
    ],
  },
  {
    header: "Margins & profitability - (Average)",
    options: [
      {
        label: "Gross profit margin",
        value: "profitability.grossProfitMargin",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Operating profit margin",
        value: "profitability.operatingProfitMargin",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Net profit margin",
        value: "profitability.netProfitMargin",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Operating cashflow margin",
        value: "profitability.operatingCashFlowMargin",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Free cashflow margin",
        value: "profitability.freeCashFlowMargin",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Free cashflow to net income margin",
        value: "profitability.freeCashFlowToNetIncome",
        type: "Single",
        suffix: "%",
      },
      {
        label: "Net interest margin",
        value: "profitability.netInterestMargin",
        type: "Single",
        suffix: "%",
      },
    ],
  },
  {
    header: "Historic Valuation Multiples",
    options: [
      {
        label: "Price to sales ratio (P/S)",
        value: "valuationMultiples.priceToSalesRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Price to earnings ratio (P/E)",
        value: "valuationMultiples.priceEarningsRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Price to operating income ratio (P/OI)",
        value: "valuationMultiples.priceOperatingIncomeRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Price to book ratio (P/B)",
        value: "valuationMultiples.priceToBookRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Price to tangible book value ratio (P/TBV)",
        value: "valuationMultiples.priceToTangibleBookRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Price to operating cashflow ratio (P/OCF)",
        value: "valuationMultiples.priceToOperatingCashFlowsRatio",
        type: "Single",
        suffix: "x",
      },
      {
        label: "Price to free cashlow ratio (P/FCF)",
        value: "valuationMultiples.priceToFreeCashFlowsRatio",
        type: "Single",
        suffix: "x",
      },
    ],
  },
  {
    header: "MOAT",
    options: [
      {
        label: "Asset turnover",
        value: "moat.assetTurnover",
        type: "Single",
        suffix: "%",
      },
    ],
  },
  {
    header: "Business Quality",
    options: [
      {
        label: "Reinvestment Rate",
        value: "businessQuality.reinvestmentRate",
        type: "Single",
        suffix: "%",
      },
    ],
  },
  {
    header: "Share Price Movement",
    options: [
      {
        label: "% Change from 52 Week Low",
        value: "info.percentChangeFromYearLow",
        type: "Single",
        suffix: "%",
      },
      {
        label: "% change from 52 Week High",
        value: "info.percentChangeFromYearHigh",
        type: "Single",
        suffix: "%",
      },
      {
        label: "YTD Returns %",
        value: "pastReturns.ytd",
        type: "Single",
        suffix: "%",
      },
      {
        label: "1 Year Returns %",
        value: "pastReturns.1Y",
        type: "Single",
        suffix: "%",
      },
      {
        label: "3 Year Returns %",
        value: "pastReturns.3Y",
        type: "Single",
        suffix: "%",
      },
      {
        label: "5 Year Returns %",
        value: "pastReturns.5Y",
        type: "Single",
        suffix: "%",
      },
      {
        label: "10 Year Returns %",
        value: "pastReturns.10Y",
        type: "Single",
        suffix: "%",
      },
    ],
  },
]

export const operators = [
  { label: "Better than", value: "gt" },
  { label: "Better than or Equal To", value: "gte" },
  { label: "Equal to", value: "eq" },
  { label: "Less Than", value: "lt" },
  { label: "Less Than or Equal to", value: "lte" },
]

export const values = [
  { label: "VERY GOOD", value: "VERY GOOD" },
  { label: "GOOD", value: "GOOD" },
  { label: "AVERAGE", value: "AVERAGE" },
  { label: "POOR", value: "POOR" },
  { label: "BAD", value: "BAD" },
]
