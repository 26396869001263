import React from "react"
import Chip from "@mui/material/Chip"
import { palette } from "../../../utils/Palette"

const RatingChip = ({ score }) => {
  const getRatingDetails = (score) => {
    if (score > 4) return { label: "VERY GOOD", color: palette["score-5-bg"] } // Green
    if (score > 3) return { label: "GOOD", color: palette["score-4-bg"] } // Light Green
    if (score > 2) return { label: "AVERAGE", color: palette["score-3-bg"] } // Yellow
    if (score > 1) return { label: "POOR", color: palette["score-2-bg"] } // Orange
    if (score > 0) return { label: "BAD", color: palette["score-1-bg"] } // Red
    return { label: "N/A", color: "#9e9e9e" } // Grey for N/A
  }
  const { label, color } = getRatingDetails(score)

  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: color,
        color: "white", // Ensure text contrast
        fontSize: "0.75rem",
        fontWeight: "bold",
        borderRadius: "4px", // Reduced border radius
        width: "100px", // Fixed width to accommodate up to 5 characters
        height: "24px", // Adjust height for alignment with fixed width
        textAlign: "center", // Center text horizontally
        justifyContent: "center", // Center text inside Chip
        overflow: "hidden", // Hide text overflow
        whiteSpace: "nowrap", // Prevent text wrapping
        padding: 0, // Remove additional padding
      }}
    />
  )
}

export default RatingChip
