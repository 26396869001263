import {
  BrowserRouter,
  Route,
  Routes,
  Link as RouterLink,
} from "react-router-dom"
import React, { useState } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Landing from "./components/Landing"
import Loading from "./components/Loading"
import Dashboard from "./components/Dashboard"
import ProtectedRoute from "./components/ProtectedRoute"
import AppDataContext from "./context/data"
import AuthDataContext from "./context/auth"
import { palette } from "./utils/Palette"
import ReactGA from "react-ga4"
import CookieConsent from "react-cookie-consent"
import Link from "@mui/material/Link"

function App() {
  ReactGA.initialize("G-B86FY908FQ")
  ReactGA.send("pageview")
  const [loggedInUser, setLoggedInUser] = useState(null)
  const [recentSearches, setRecentSearches] = useState([])
  const [overview, setOverview] = useState({})
  const [incomeStatment, setIncomeStatment] = useState({})
  const [balanceSheet, setBalanceSheet] = useState({})
  const [cashFlowStatment, setCashFlowStatment] = useState({})
  const [ratios, setRatios] = useState({})
  const [keyFinancials, setKeyFinancials] = useState({})
  const [prosAndCons, setProsAndCons] = useState({})
  const [fingraphs, setFingraphs] = useState({})
  const [keyInsights, setKeyInsights] = useState({})
  const [valuationRef, setValuationRef] = useState({})
  const [checkList, setCheckList] = useState({})
  const [profile, setProfile] = useState({})
  const [secFilings, setSecFilings] = useState({})
  const [shareholders, setShareholders] = useState({})
  const [insiderTransactions, setInsiderTransactions] = useState({})
  const [dividends, setDividends] = useState({})
  const [analysts, setAnalysts] = useState({})
  const [strapiTerms, setStrapiTerms] = useState({})
  const [quotes, setQuotes] = useState({})
  const [watchlists, setWatchlists] = useState({})
  const [peers, setPeers] = useState({})
  const [revenueSegments, setRevenueSegments] = useState({})
  const [earningsCallTranscripts, setEarningsCallTranscripts] = useState({})
  const [indexData, setIndexData] = useState({})
  return (
    <>
      <AuthDataContext.Provider value={{ loggedInUser, setLoggedInUser }}>
        <AppDataContext.Provider
          value={{
            recentSearches,
            setRecentSearches,
            overview,
            setOverview,
            incomeStatment,
            setIncomeStatment,
            balanceSheet,
            setBalanceSheet,
            cashFlowStatment,
            setCashFlowStatment,
            ratios,
            setRatios,
            keyFinancials,
            setKeyFinancials,
            prosAndCons,
            setProsAndCons,
            fingraphs,
            setFingraphs,
            keyInsights,
            setKeyInsights,
            valuationRef,
            setValuationRef,
            checkList,
            setCheckList,
            profile,
            setProfile,
            secFilings,
            setSecFilings,
            shareholders,
            setShareholders,
            insiderTransactions,
            setInsiderTransactions,
            dividends,
            setDividends,
            analysts,
            setAnalysts,
            strapiTerms,
            setStrapiTerms,
            quotes,
            setQuotes,
            peers,
            setPeers,
            revenueSegments,
            setRevenueSegments,
            earningsCallTranscripts,
            setEarningsCallTranscripts,
            indexData,
            setIndexData,
            watchlists,
            setWatchlists,
          }}
        >
          <BrowserRouter>
            <React.Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" name="Landing" element={<Landing />} />
                <Route
                  path="/dashboard/*"
                  name="Dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </React.Suspense>
            <CookieConsent
              location="bottom"
              buttonText="Sounds good!"
              cookieName="LVICookiePolicy"
              style={{
                background: "#343a40",
                color: "#f8f9fa",
                padding: "10px 15px",
              }}
              buttonStyle={{
                background: "#f8f9fa",
                color: "#343a40",
                fontSize: "16px",
                borderRadius: "5px",
                padding: "5px 15px",
              }}
              buttonHoverStyle={{ background: "#218838" }}
              expires={150}
            >
              We use cookies to improve your experience. By continuing, you
              agree to our use of cookies. No surprises here, just tasty data.{" "}
              <Link
                component={RouterLink}
                to="/dashboard/guides/privacy-policy"
                underline="hover"
                style={{ color: "#00c3ff" }}
              >
                Learn more
              </Link>
            </CookieConsent>
          </BrowserRouter>
        </AppDataContext.Provider>
      </AuthDataContext.Provider>
      <ToastContainer
        toastStyle={{
          backgroundColor: palette["grey-1"],
          color: palette["white"],
        }}
      />
    </>
  )
}

export default App
