import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../../../styles"
import { TextThousandSeparator } from "../../../../../../utils/NumberFormatters"
import { isBankInsuranceOrREIT } from "../../../../../../utils/KeyInsightsScores"
import Rating from "../../../../../Chip/Rating"
import Score from "../../../../../Chip/Score"
import Term from "../../../../../../utils/Term"

const FinancialHealth = ({ keyInsights, overview }) => {
  const classes = useStyles()
  let allRows = [
    {
      title: "Current ratio",
      key: "currentRatio",
      ext: "",
      defToolTip: {
        key: "current_ratio",
        type: "Key Insights",
      },
    },
    {
      title: "Debt to equity ratio",
      key: "debtEquityRatio",
      ext: "",
      defToolTip: {
        key: "debt_to_equity_ratio",
        type: "Key Insights",
      },
    },
    {
      title: "Debt to Op cash flow",
      key: "debtToCashflowRatio",
      ext: "x",
      defToolTip: {
        key: "debt_to_ocf_ratio",
        type: "Key Insights",
      },
    },
    {
      title: "Intangibles as % to Assets",
      key: "goodwillAndIntangibleAssetsToTotalAssets",
      ext: "%",
      defToolTip: {
        key: "intangibles_as_percentage_of_total_assets_ratio",
        type: "Key Insights",
      },
    },
    {
      title: "Interest coverage",
      key: "interestCoverage",
      ext: "x",
      defToolTip: {
        key: "interest_coverage_ratio",
        type: "Key Insights",
      },
    },
  ]
  let financeSpecificRows = [
    {
      title: "Current ratio",
      key: "currentRatio",
      ext: "",
      defToolTip: {
        key: "current_ratio",
        type: "Key Insights",
      },
    },
    {
      title: "Debt to equity ratio",
      key: "debtEquityRatio",
      ext: "",
      defToolTip: {
        key: "debt_to_equity_ratio",
        type: "Key Insights",
      },
    },
    {
      title: "Interest coverage",
      key: "interestCoverage",
      ext: "x",
      defToolTip: {
        key: "interest_coverage_ratio",
        type: "Key Insights",
      },
    },
  ]
  const [rows] = useState(
    isBankInsuranceOrREIT(
      overview?.profile?.sector,
      overview?.profile?.industry
    )
      ? financeSpecificRows
      : allRows
  )

  // To display formatted value
  const displayValue = (
    value,
    ext = "%",
    thousandSeparator = false,
    isSuffix = true
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Box className={classes["big-numbers"]}>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <Box className={classes["table-header"]}>
                  <span>
                    <div className={classes["main-header"]}>
                      Financial health
                    </div>
                    <div className={classes["sub-header"]}>
                      Helps evaluate if the business is financially sound
                    </div>
                  </span>
                  <span>
                    <Rating score={keyInsights?.financialHealth.score} />
                    <Score score={keyInsights?.financialHealth.score} />
                  </span>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Metrics</TableCell>
              <TableCell align="center">10Yr</TableCell>
              <TableCell align="center">5Yr</TableCell>
              <TableCell align="center">3Yr</TableCell>
              <TableCell align="center">TTM</TableCell>
              <TableCell align="center">Rating</TableCell>
            </TableRow>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.financialHealth[row.key]?.tenYear,
                    row?.ext
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.financialHealth[row.key]?.fiveYear,
                    row?.ext
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.financialHealth[row.key]?.threeYear,
                    row?.ext
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.financialHealth[row.key]?.ttm ||
                      keyInsights?.financialHealth[row.key]?.lastQuarter,
                    row?.ext
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  <Rating
                    score={keyInsights?.financialHealth[row.key]?.score}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default FinancialHealth

FinancialHealth.propTypes = {
  keyInsights: PropTypes.object.isRequired,
}
