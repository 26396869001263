import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Typography,
  Link,
} from "@mui/material"
import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

import useStyles from "../styles"

const News = ({ name, data }) => {
  const classes = useStyles()
  let header = "News related to stocks you follow"
  if (name) {
    header = "Latest news on " + name
  }

  return (
    <Box>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>{header}</div>
                <div className={classes["sub-header"]}></div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.slice(0, 10).map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell align="left">
                  <Box display={"flex"} flexDirection={"row"}>
                    <Box display="flex" justifyContent="flex-end">
                      <Link href={row?.url} target="_blank">
                        <img
                          src={row?.image}
                          alt=""
                          width="100px"
                          height="80px"
                        />
                      </Link>
                    </Box>
                    <Box>
                      <Typography>
                        <span className={classes.site}>
                          {row?.site}
                          <span className={classes.momentAgo}>
                            {moment(row?.publishedDate).fromNow()}
                          </span>
                        </span>
                      </Typography>
                      <Typography>
                        <Link
                          href={row?.url}
                          target="_blank"
                          className={classes.title}
                        >
                          {row?.title}
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default News

News.propTypes = {
  data: PropTypes.array.isRequired,
}
