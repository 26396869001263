import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Box, Grid, Typography, Tooltip, Button } from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import AddIcon from "@mui/icons-material/Add"
import PropTypes from "prop-types"
import {
  ArrowUpward,
  ArrowDownward,
  FormatQuote,
  InfoOutlined,
} from "@mui/icons-material"
import AppDataContext from "../../../context/data"
import {
  getRatingTemplate,
  getScoreTemplate,
} from "../../../utils/KeyInsightsScores"
import { format } from "date-fns"
import { palette } from "../../../utils/Palette"
import { getClassification } from "../../../utils/misc"

import useStyles from "./styles"
import { TextThousandSeparator } from "../../../utils/NumberFormatters"
import {
  getFavoriteStatusAndCount,
  removeTicker,
  addOrUpdateTicker,
} from "../../../services/watchlist"
import { toast } from "react-toastify"

const TickerHeader = ({ data }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { quotes } = useContext(AppDataContext)
  const { watchlists } = useContext(AppDataContext)
  const { setWatchlists } = useContext(AppDataContext)
  const [randomQuote, setRandomQuote] = useState("")
  const [isFavorited, setIsFavorited] = useState(false)
  const userId = window.sessionStorage.getItem("userId")
  useEffect(() => {
    // this will make sure quote is displayed only once and that is  when the URL changes
    const randomIndex = Math.floor(Math.random() * quotes.length)
    setRandomQuote(quotes[randomIndex])
  }, [pathname, quotes])

  useEffect(() => {
    //API call to check if the ticker is favorited for the user
    const getFavoriteDetails = async () => {
      try {
        const resp = await getFavoriteStatusAndCount(userId, data?.tickerId)
        setIsFavorited(resp?.data?.isFavorited)
      } catch (err) {}
    }
    getFavoriteDetails()
  }, [data?.tickerId, userId])

  function updateWatchlists(
    watchlists,
    updatedWatchlist,
    tickerId,
    isAdding,
    watchlistId
  ) {
    return watchlists.map((watchlist) => {
      if (watchlist.id === watchlistId) {
        const existingTickerIndex = watchlist.tickers.findIndex(
          (t) => t.tickerId === tickerId
        )

        const addedOrUpdatedTicker = updatedWatchlist.tickers.find(
          (ticker) => ticker.tickerId === tickerId
        )

        if (isAdding) {
          // If ticker doesn't exist, add it
          if (existingTickerIndex === -1) {
            return {
              ...watchlist,
              tickers: [
                ...watchlist.tickers,
                {
                  ...addedOrUpdatedTicker,
                  id: addedOrUpdatedTicker._id,
                  overallScore: data?.overallScore,
                  name: data?.keyInsightsData?.info.name,
                  price: data?.keyInsightsData?.info.price,
                  change: data?.keyInsightsData?.info.change,
                  changesPercentage:
                    data?.keyInsightsData?.info.changesPercentage,
                  ytdReturn: data?.keyInsightsData?.pastReturns.ytd,
                  yearlyReturn: data?.keyInsightsData?.pastReturns["1Y"],
                  fiveYearReturn: data?.keyInsightsData?.pastReturns["5Y"],
                  yearLow: data?.keyInsightsData?.info?.yearLow,
                  percentChangeFromYearLow:
                    data?.keyInsightsData?.info?.percentChangeFromYearLow,
                  yearHigh: data?.keyInsightsData?.info?.yearHigh,
                  percentChangeFromYearHigh:
                    data?.keyInsightsData?.info?.percentChangeFromYearHigh,
                  growthScore: data?.keyInsightsData?.growthRates?.score,
                  managementEffectivenessScore:
                    data?.keyInsightsData?.managementEffectiveness?.score,
                  financialHealthScore:
                    data?.keyInsightsData?.financialHealth?.score,
                  valuationMultiplesScore:
                    data?.keyInsightsData?.valuationMultiples?.score,
                  profitabilityScore:
                    data?.keyInsightsData.profitability?.score,
                  moatScore: data?.keyInsightsData?.moat?.score,
                  businessQualityScore:
                    data?.keyInsightsData?.businessQuality?.score,
                  dividendScore: data?.keyInsightsData?.dividend?.score,
                },
              ],
            }
          } else {
            // If ticker exists, update it (e.g., add/update additional properties)
            const updatedTickers = [...watchlist.tickers]
            updatedTickers[existingTickerIndex] = {
              ...updatedTickers[existingTickerIndex],
              // Add or update properties here
            }
            return {
              ...watchlist,
              tickers: updatedTickers,
            }
          }
        } else {
          // Remove the ticker
          return {
            ...watchlist,
            tickers: watchlist.tickers.filter((t) => t.tickerId !== tickerId),
          }
        }
      } else {
        return watchlist
      }
    })
  }

  const handleFavoriteClick = async () => {
    try {
      if (isFavorited) {
        // Unfavorite the ticker
        const response = await removeTicker(
          userId,
          watchlists[0]._id,
          data?.tickerId
        )
        if (response.status === 200) {
          // Update watchlist state in context
          const updatedWatchlists = updateWatchlists(
            watchlists,
            response?.data,
            data?.tickerId,
            false,
            watchlists[0].id // Assuming the first watchlist is the target
          )
          setWatchlists(updatedWatchlists)
          toast.success("You are no longer following " + data?.tickerId)
        } else {
          toast.error(
            "There was a problem unfollowing this ticker " +
              data?.tickerId +
              ". Please try again."
          )
        }
      } else {
        // Favorite the ticker
        const response = await addOrUpdateTicker(
          userId,
          watchlists[0]._id,
          data?.tickerId,
          JSON.stringify({ notes: "", priceTarget: 0 })
        )
        if (response.status === 200) {
          // Update watchlist state in context
          const updatedWatchlists = updateWatchlists(
            watchlists,
            response?.data,
            data?.tickerId,
            true,
            watchlists[0].id // Assuming the first watchlist is the target
          )
          setWatchlists(updatedWatchlists)
          toast.success("You have started following " + data?.tickerId)
        } else {
          toast.error(
            "There was a problem following this ticker " +
              data?.tickerId +
              ". Please try again."
          )
        }
      }
      setIsFavorited(!isFavorited)
    } catch (error) {
      console.error(error)
      // Handle error, e.g., show an error message to the user
    }
  }

  // To display formatted value
  const displayValue = (
    value,
    ext = "",
    thousandSeparator = true,
    isSuffix = false,
    fixedDecimalScale = false
  ) => {
    if (!value) return "-"
    const absValue = Math.abs(value)
    if (absValue > 999 && absValue <= 1000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000).toFixed(2)
      isSuffix = true
      ext = "K"
    } else if (absValue >= 1000000 && absValue < 1000000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000000).toFixed(2)
      isSuffix = true
      ext = "M"
    } else if (absValue >= 1000000000 && absValue < 1000000000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000000000).toFixed(2)
      isSuffix = true
      ext = "B"
    } else if (absValue >= 1000000000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000000000000).toFixed(2)
      isSuffix = true
      ext = "T"
    }

    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
        fixedDecimalScale={fixedDecimalScale}
      />
    )
  }

  return (
    <Grid container direction="row">
      <Grid item md={4}>
        <Box className={classes["header-container"]}>
          {data?.image && (
            <Box
              component="img"
              sx={{
                maxHeight: 60,
                maxWidth: 80,
              }}
              alt=""
              src={data?.image}
            />
          )}
          <Box className={classes.container}>
            <Box className={classes["row-container"]}>
              <Typography className={classes.name}>
                {data?.name}
                <span className={classes.symbol}>
                  {data?.tickerId}
                  <span className={classes.exchange}>{data?.exchange}</span>
                </span>
              </Typography>
            </Box>
            <Box className={classes["row-container"]}>
              <Typography className={classes.price}>
                {displayValue(data?.price, "$", true, false, true)}
              </Typography>
              <Typography
                className={`${classes.priceChangePer} ${
                  data?.changesPercentage < 0
                    ? "percentage-down-txt"
                    : "percentage-up-txt"
                }`}
              >
                {data?.changesPercentage > 0 ? (
                  <ArrowUpward sx={{ fontSize: 20 }} className={classes.icon} />
                ) : (
                  <ArrowDownward
                    sx={{ fontSize: 20 }}
                    className={classes.icon}
                  />
                )}
                {data?.changesPercentage > 0 ? "+" : ""}
                {displayValue(data?.changesPercentage, "%", true, true, true)}
              </Typography>
              <Typography
                className={`${classes.priceChange} ${
                  data?.change < 0 ? "error-txt" : "success-txt"
                }`}
              >
                {data?.change > 0 ? `+${data?.change}` : data.change}
              </Typography>
            </Box>
            <Box className={classes.overallScore}>
              <Box sx={{ mt: 1 }}>
                {getRatingTemplate(data?.overallScore, classes)}
              </Box>
              <Box sx={{ ml: 1, mt: 1 }}>
                {getScoreTemplate(data?.overallScore, classes)}
              </Box>
              <Box sx={{ pt: 1 }}>
                <Button
                  size="small"
                  color="error"
                  variant="outlined"
                  sx={{
                    mt: 0,
                    pt: 0,
                    pb: 0,
                    fontSize: 14,
                    textTransform: "none",
                  }}
                  onClick={handleFavoriteClick}
                >
                  {isFavorited ? (
                    <>
                      <DoneIcon />
                      Following
                    </>
                  ) : (
                    <>
                      <AddIcon />
                      Follow
                    </>
                  )}
                </Button>
              </Box>
              {/* <Box sx={{ pt: 1 }}>
                <AddCircleOutlineOutlinedIcon fontSize="large" />
              </Box>
              <Box sx={{ fontSize: 14, pt: 1, fontStyle: "italic" }}>
                71 Following
              </Box> */}
            </Box>
            <Box display="flex" flexDirection="row" sx={{ mt: 1, mb: -1 }}>
              <span className={classes.earnings}>
                {new Date(data?.earningsAnnouncement).getTime() >=
                new Date().getTime()
                  ? "Next earning: "
                  : "Last earning: "}
                {data?.earningsAnnouncement
                  ? format(new Date(data?.earningsAnnouncement), "MMMM d, yyyy")
                  : ""}
              </span>
              <span>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography variant="body2">
                        <strong>Market Cap: </strong>$
                        {displayValue(data?.bigNumbers?.marketCap)} (
                        {getClassification(data?.profile?.marketCap)})
                      </Typography>
                      <Typography variant="body2">
                        <strong>Enterprise Value: </strong>$
                        {displayValue(data?.bigNumbers?.enterpriseValue)}
                      </Typography>
                      <Typography variant="body2">
                        <strong>52 week price range: </strong>$
                        {displayValue(data?.shareStats?.yearLow)}
                        {" - "}${displayValue(data?.shareStats?.yearHigh)}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Earnings Yield: </strong>
                        {displayValue(
                          data?.currentYields?.earningsYield,
                          "%",
                          "true",
                          "true",
                          "true"
                        )}
                      </Typography>
                      <Typography variant="body2">
                        <strong>FCF Yield: </strong>
                        {displayValue(
                          data?.currentYields?.freeCashFlowYield,
                          "%",
                          "true",
                          "true",
                          "true"
                        )}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Dividend Yield: </strong>
                        {displayValue(
                          data?.currentYields?.forwardDividendYield,
                          "%",
                          "true",
                          "true",
                          "true"
                        )}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {data?.profile?.sector}/{data?.profile?.industry}
                      </Typography>
                    </React.Fragment>
                  }
                  placement="right"
                >
                  <InfoOutlined
                    style={{ color: palette["purple-2"], marginLeft: "10px" }}
                    className={"cursor-pointer"}
                  ></InfoOutlined>
                </Tooltip>
              </span>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item md={8}>
        <Box>
          <Typography>
            <Box
              sx={{
                p: 2,
                mt: { lg: 3 },
                mb: 1,
                mr: { lg: 5 },
                maxWidth: "lg",
                border: "1px dashed grey",
                backgroundColor: "lightYellow",
              }}
            >
              <FormatQuote />
              {randomQuote?.description}
              <FormatQuote />
              <span className={classes["author-name"]}>
                - {randomQuote?.author}.
              </span>
            </Box>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TickerHeader

TickerHeader.propTypes = {
  data: PropTypes.object.isRequired,
}
