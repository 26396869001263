import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import useStyles from "./styles"
import AppDataContext from "../../../context/data"
import { getProfile } from "../../../services/ticker"
import Loading from "../../Loading"
import ExecutivesTable from "./components/ExecutivesTable"
import TickerProfile from "./components/TickerProfile"
import ReactGA from "react-ga4"

const Profile = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/profile",
  })
  const [isLoading, setIsLoading] = useState(false)
  const { tickerId } = useParams()
  const { profile, setProfile, overview } = useContext(AppDataContext)
  const classes = useStyles()

  useEffect(() => {
    document.title = `${tickerId} - Profile - Lets Value Invest`
    setIsLoading(true)
    const getProfileData = async () => {
      toast.dismiss()
      try {
        // get data only if context is empty and save into context
        const resp = await getProfile(tickerId)
        setProfile({ ...resp.data, tickerId })
        setIsLoading(false)
      } catch (err) {
        setProfile(null)
        setIsLoading(false)
        toast.error(
          "Unable to get profile information. Please try after sometime."
        )
      }
    }
    // check if we have data already in context
    if (profile?.tickerId === tickerId) {
      setIsLoading(false)
      return
    }
    overview?.tickerId && getProfileData()
  }, [JSON.stringify(overview), tickerId])

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box className={classes["ticker-container"]}>
      <Grid container rowSpacing={3} columnSpacing={4}>
        <Grid item xs={12} sm={8}>
          <Box>
            <Typography className={classes["header"]}>About</Typography>
            <Typography className={classes["content-txt"]}>
              {profile?.profile?.description}
            </Typography>
          </Box>
          <Box>
            {profile?.keyExecutives && (
              <ExecutivesTable keyExecutives={profile?.keyExecutives} />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            {profile?.profile && <TickerProfile profile={profile?.profile} />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Profile
