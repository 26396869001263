import React from "react"
import Chip from "@mui/material/Chip"
import { palette } from "../../../utils/Palette"

const ScoreChip = ({ score }) => {
  const getScoreDetails = (score) => {
    if (score > 4)
      return {
        color: palette["score-5-bg"],
        backgroundColor: palette["tot-score-5-bg"],
      }
    if (score > 3)
      return {
        color: palette["score-4-bg"],
        backgroundColor: palette["tot-score-4-bg"],
      }
    if (score > 2)
      return {
        color: palette["score-3-bg"],
        backgroundColor: palette["tot-score-3-bg"],
      }
    if (score > 1)
      return {
        color: palette["score-2-bg"],
        backgroundColor: palette["tot-score-2-bg"],
      }
    if (score >= 0)
      return {
        color: palette["score-1-bg"],
        backgroundColor: palette["tot-score-1-bg"],
      }
  }
  const { color, backgroundColor } = getScoreDetails(score)
  const label = score + "/5"

  return (
    <Chip
      label={label}
      variant="outlined"
      sx={{
        backgroundColor: backgroundColor,
        color: color, // Ensure text contrast
        fontSize: "0.75rem",
        fontWeight: "bold",
        height: "24px", // Adjust height for alignment with fixed width
        textAlign: "center", // Center text horizontally
        justifyContent: "center", // Center text inside Chip
        overflow: "hidden", // Hide text overflow
        whiteSpace: "nowrap", // Prevent text wrapping
        padding: 0, // Remove additional padding
        marginLeft: 1,
      }}
    />
  )
}

export default ScoreChip
