import React from "react"
import { Box, Typography, Grid, Link, Chip, Stack } from "@mui/material"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import "primereact/resources/themes/lara-light-cyan/theme.css"
import { TextThousandSeparator } from "../../utils/NumberFormatters"
import { useNavigate } from "react-router-dom"
import {
  getRatingTemplate,
  getMoatFromScore,
} from "../../../src/utils/KeyInsightsScores"

import useStyles from "./styles"
import StockPriceRange from "./stockPriceRange"
const _ = require("lodash")

function FilteredStocks({ filteredStocks, filters }) {
  const classes = useStyles()
  const navigate = useNavigate()
  // To display formatted value
  const displayValue = (
    value,
    ext = "$",
    thousandSeparator = true,
    isSuffix = false
  ) => {
    if (!value) return "-"
    if (isSuffix) {
      value = value.toFixed(2)
    } else {
      const absValue = Math.abs(value)
      if (absValue > 999 && absValue <= 1000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000).toFixed(2)
        isSuffix = true
        ext = "K"
      } else if (absValue >= 1000000 && absValue < 1000000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000000).toFixed(2)
        isSuffix = true
        ext = "M"
      } else if (absValue >= 1000000000 && absValue < 1000000000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000000000).toFixed(2)
        isSuffix = true
        ext = "B"
      } else if (absValue >= 1000000000000) {
        // convert to K for number from > 1000 < 1 million
        value = (value / 1000000000000).toFixed(2)
        isSuffix = true
        ext = "T"
      }
    }

    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  const header = () => {
    return (
      <Typography className={classes.momentAgo} variant="body1">
        Displaying <strong>{filteredStocks.length}</strong> matching stocks:
      </Typography>
    )
  }

  const onIndexTemplate = (data, props) => {
    return props.rowIndex + 1
  }

  // Generic score template function (using template concept)
  const scoreTemplate = (stock, fieldPath, suffix) => {
    // Split the field path into an array of property names
    const fieldNames = fieldPath.split(".")
    // Traverse the nested object to reach the desired score
    let value = stock
    for (const fieldName of fieldNames) {
      value = value?.[fieldName]
      if (value === undefined) {
        return null // Return null if any property in the path is missing
      }
    }
    if (fieldPath === "moat.score") {
      return (
        <Box sx={{ textAlign: "center" }}>
          {getMoatFromScore(value, classes)}
        </Box>
      )
    }
    if (suffix) {
      return (
        <Box sx={{ textAlign: "center" }}>
          {displayValue(value, suffix, true, true)}
        </Box>
      )
    }
    return (
      <Box sx={{ textAlign: "center" }}>
        {getRatingTemplate(value, classes)}
      </Box>
    )
  }

  const ratingBodyTemplate = (stock, column) => {
    return scoreTemplate(stock, column.field)
  }

  const percentBodyTemplate = (stock, column) => {
    return scoreTemplate(stock, column.field, "%")
  }

  const timesBodyTemplate = (stock, column) => {
    return scoreTemplate(stock, column.field, "x")
  }

  const tickerIdBodyTemplate = (stock) => {
    return (
      <Box sx={{ textAlign: "left" }}>
        <Link
          onClick={() => {
            navigate(`/dashboard/ticker/${stock?.tickerId}/overview`)
          }}
          className={classes["custom-link"]}
        >
          {stock?.tickerId}
        </Link>
        <Typography variant="subtitle1" display="block">
          {stock?.info?.name}
        </Typography>
        <Typography variant="caption" display="block">
          <Stack direction="row" spacing={1}>
            <Chip label={stock?.info?.sector} size="small" />
            <Chip label={stock?.info?.classification} size="small" />
          </Stack>
        </Typography>
      </Box>
    )
  }

  const marketCapBodyTemplate = (stock) => {
    return displayValue(stock?.info?.marketCap)
  }

  const stockPriceRangeBodyTemplate = (stock) => {
    const range = {
      fiftyTwoWeekLow: stock?.info?.yearLow?.toFixed(2),
      fiftyTwoWeekHigh: stock?.info?.yearHigh?.toFixed(2),
      currentPrice: stock?.info?.price,
    }
    return <StockPriceRange {...range} />
  }

  const generateColumns = (filters) => {
    return filters.map((filter) => {
      const { metric } = filter // Destructure filter object
      if (metric.value === "overallScore" || metric.label === "Rating") {
        // no columns for rating as it is already included
        return null
      }
      if (metric.suffix === "%") {
        return (
          <Column
            key={metric.value} // Unique key for each column
            field={metric.value}
            header={metric.label}
            sortable // Make the column sortable (optional)
            body={percentBodyTemplate}
            style={{
              minWidth: "10rem",
              maxWidth: "10rem",
              textAlign: "center",
            }}
          />
        )
      }
      if (metric.suffix === "x") {
        return (
          <Column
            key={metric.value} // Unique key for each column
            field={metric.value}
            header={metric.label}
            sortable // Make the column sortable (optional)
            body={timesBodyTemplate}
            style={{
              minWidth: "10rem",
              maxWidth: "10rem",
              textAlign: "center",
            }}
          />
        )
      }
      return (
        <Column
          key={metric.value} // Unique key for each column
          field={metric.value}
          header={metric.label}
          sortable // Make the column sortable (optional)
          body={ratingBodyTemplate}
          style={{ minWidth: "10rem" }}
        />
      )
    })
  }

  return (
    <Grid container direction="row" className={classes.LVIDataTable}>
      <Grid item xs={12}>
        {/* Use DataGrid or List component */}
        <DataTable
          value={filteredStocks}
          size="small"
          header={header}
          showGridlines
          stripedRows
          paginator
          rows={50}
          rowsPerPageOptions={[50, 100]}
          sortOrder={-1}
          sortMode="multiple"
          removableSort
          // sortField="tickerId"
          // sortOrder={-1}
          emptyMessage="No stocks found."
          reorderableColumns
        >
          <Column field="Index" header="" body={onIndexTemplate} />
          <Column
            field="tickerId"
            header="Symbol"
            body={tickerIdBodyTemplate}
            style={{ minWidth: "15rem" }}
          ></Column>
          <Column
            field="info.marketCap"
            sortable
            header="Market Cap"
            body={marketCapBodyTemplate}
          ></Column>
          <Column
            field="info.price"
            header="52 Week Range"
            style={{ minWidth: "15rem" }}
            body={stockPriceRangeBodyTemplate}
          ></Column>
          <Column
            field="overallScore"
            header="RATING"
            sortable
            body={ratingBodyTemplate}
            style={{ minWidth: "10rem" }}
          ></Column>
          {generateColumns(filters)}
        </DataTable>
      </Grid>
    </Grid>
  )
}

export default FilteredStocks
