import React, { useEffect } from "react"
import { Box } from "@mui/material"
import "react-toastify/dist/ReactToastify.css"
import { Route, Routes } from "react-router-dom"
import Cookies from "js-cookie"
import { getVersion } from "../../services/core"

import Header from "../Header"
import Footer from "../Footer"
import Home from "../Home"
import Dashboard from "../Dashboard"
import useStyles from "./styles"
import ReactGA from "react-ga4"

const Landing = () => {
  const classes = useStyles()
  ReactGA.send({ hitType: "pageview", page: "/landing" })

  useEffect(() => {
    const getCurrentVersion = async () => {
      try {
        const response = await getVersion()
        const currentVersion = Cookies.get("lvi_version")
        const version = response?.data?.version
        if (currentVersion !== version) {
          //alert("A new update is available! The page will now reload.")
          Cookies.set("lvi_version", version, {
            path: "/",
            expires: 365,
            sameSite: "Strict",
          })
          if ("caches" in window) {
            caches.keys().then(function (cacheNames) {
              cacheNames.forEach(function (cacheName) {
                caches.delete(cacheName)
              })
            })
          }
          window.location.reload(true)
        }
      } catch (err) {
        console.error("Error fetching version:", err)
      }
    }

    getCurrentVersion()
  }, [])

  return (
    <Box className={classes["home-container"]}>
      <Box className={classes["header-container"]}>
        <Header />
      </Box>
      <Box className={classes["content-container"]} flex={1}>
        <Routes>
          <Route path="/" name="dashboard" element={<Home />} />
          <Route path="/dashboard" name="dashboard" element={<Dashboard />} />
        </Routes>
      </Box>
      <Box className={classes["footer-container"]}>
        <Footer />
      </Box>
    </Box>
  )
}

export default Landing
