import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../../../styles"
import { TextThousandSeparator } from "../../../../../../utils/NumberFormatters"
import { isBankInsuranceOrREIT } from "../../../../../../utils/KeyInsightsScores"
import Rating from "../../../../../Chip/Rating"
import Score from "../../../../../Chip/Score"
import Term from "../../../../../../utils/Term"

const Dividend = ({ keyInsights, overview }) => {
  const classes = useStyles()
  const allRows = [
    {
      title: "CAGR",
      key: "growth",
      defToolTip: {
        key: "dividend_cagr",
        type: "Key Insights",
      },
    },
    {
      title: "Payout ratio",
      key: "payoutRatio",
      defToolTip: {
        key: "dividend_payout_ratio",
        type: "Key Insights",
      },
    },
    {
      title: "FCF Payout ratio",
      key: "dividendToFreeCashFlow",
      defToolTip: {
        key: "dividend_fcf_payout_ratio",
        type: "Key Insights",
      },
    },
  ]
  const financeSpecificRows = [
    {
      title: "CAGR",
      key: "growth",
      defToolTip: {
        key: "dividend_cagr",
        type: "Key Insights",
      },
    },
    {
      title: "Payout ratio",
      key: "payoutRatio",
      defToolTip: {
        key: "dividend_payout_ratio",
        type: "Key Insights",
      },
    },
  ]
  const [rows] = useState(
    isBankInsuranceOrREIT(
      overview?.profile?.sector,
      overview?.profile?.industry
    )
      ? financeSpecificRows
      : allRows
  )

  // To display formatted value
  const displayValue = (
    value,
    ext = "%",
    thousandSeparator = false,
    isSuffix = true
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Box className={classes["big-numbers"]}>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6} className={"h12"}>
                <Box className={classes["table-header"]}>
                  <span>
                    <div className={classes["main-header"]}>Dividend Info</div>
                    <div className={classes["sub-header"]}>
                      Reflects the company's ability to consistently pay and
                      grow its dividends
                    </div>
                  </span>
                  <span>
                    <Rating score={keyInsights?.dividend.score} />
                    <Score score={keyInsights?.dividend.score} />
                  </span>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Metrics</TableCell>
              <TableCell align="center">10Yr</TableCell>
              <TableCell align="center">5Yr</TableCell>
              <TableCell align="center">3Yr</TableCell>
              <TableCell align="center">TTM</TableCell>
              <TableCell align="center">Rating</TableCell>
            </TableRow>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(keyInsights?.dividend[row.key]?.tenYear)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(keyInsights?.dividend[row.key]?.fiveYear)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(keyInsights?.dividend[row.key]?.threeYear)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(keyInsights?.dividend[row.key]?.ttm)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  <Rating score={keyInsights?.dividend[row.key]?.score} />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={6}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Dividend

Dividend.propTypes = {
  keyInsights: PropTypes.object.isRequired,
}
