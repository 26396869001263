import React from "react"
import PropTypes from "prop-types"
import { Grid, Typography, Box, useTheme, useMediaQuery } from "@mui/material"

import GrowthRates from "./components/GrowthRates"
import ManagementEffectiveness from "./components/ManagementEffectiveness"
import FinancialHealth from "./components/FinancialHealth"
import MarginsProfitability from "./components/MarginsProfitability"
import ValuationMultiples from "./components/ValuationMultiples"
import Dividend from "./components/Dividend"
import Moat from "./components/Moat"
import BusinessQuality from "./components/BusinessQuality"
import useStyles from "../../styles"
import ReactGA from "react-ga4"
import Rating from "../../../../Chip/Rating"
import Score from "../../../../Chip/Score"

const DetailedView = ({ keyInsights, overview }) => {
  ReactGA.send({ hitType: "pageview", page: "/ticker/key-insights/detailed" })
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")) // Adjust based on your breakpoints
  const classes = useStyles()
  if (!keyInsights) return <></>

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      style={{ padding: "0 1rem" }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row", // Stack on smaller screens
          alignItems: isSmallScreen ? "flex-start" : "center", // Adjust alignment
        }}
      >
        <Box sx={{ mr: 1 }}>Overall Rating:</Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "row" : "row", // Keep in one row for all screens
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: isSmallScreen ? 1 : 0 }}>
            <Rating score={keyInsights?.overallScore} />
          </Box>
          <Typography sx={{ mr: 1, mt: isSmallScreen ? 1 : 0 }}>
            <Score score={keyInsights?.overallScore} />
          </Typography>
        </Box>

        <Typography
          sx={{ mt: isSmallScreen ? 1 : 0 }}
          className={classes["hint-text"]}
        >
          <i>( Based on {keyInsights?.noOfInsights} unique key insights )</i>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes["info-text"]} xs={12} md={"flex"}>
          All yearly records are as of last financial year and all TTM records
          are as of last quarterly results declared by the company.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <GrowthRates keyInsights={keyInsights} overview={overview} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ManagementEffectiveness
          keyInsights={keyInsights}
          overview={overview}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FinancialHealth keyInsights={keyInsights} overview={overview} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MarginsProfitability keyInsights={keyInsights} overview={overview} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ValuationMultiples keyInsights={keyInsights} overview={overview} />
      </Grid>
      <Grid item xs={12} md={6}>
        {keyInsights?.moat?.score && (
          <Moat keyInsights={keyInsights} overview={overview} />
        )}
      </Grid>
      {keyInsights?.businessQuality && (
        <Grid item xs={12} md={6}>
          <BusinessQuality keyInsights={keyInsights} overview={overview} />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        {keyInsights?.dividend?.score && (
          <Dividend keyInsights={keyInsights} overview={overview} />
        )}
      </Grid>
    </Grid>
  )
}

export default DetailedView

DetailedView.propTypes = {
  keyInsights: PropTypes.object.isRequired,
  overview: PropTypes.object.isRequired,
}
