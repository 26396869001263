import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../../../styles"
import { TextThousandSeparator } from "../../../../../../utils/NumberFormatters"
import { isBankInsuranceOrREIT } from "../../../../../../utils/KeyInsightsScores"
import Score from "../../../../../Chip/Score"
import Rating from "../../../../../Chip/Rating"
import Term from "../../../../../../utils/Term"

const ManagementEffectiveness = ({ keyInsights, overview }) => {
  const classes = useStyles()
  let allRows = [
    {
      title: "Return on assets (ROA)",
      key: "returnOnAssets",
      defToolTip: {
        key: "return_on_assets",
        type: "Key Insights",
      },
    },
    {
      title: "Return on equity (ROE)",
      key: "returnOnEquity",
      defToolTip: {
        key: "return_on_equity",
        type: "Key Insights",
      },
    },
    {
      title: "Return on capital employed (ROCE)",
      key: "returnOnCapitalEmployed",
      defToolTip: {
        key: "return_on_capital_employed",
        type: "Key Insights",
      },
    },
    {
      title: "Return on inremental capital employed",
      key: "returnOnIncrementalCapitalEmployed",
      defToolTip: {
        key: "return_on_incremental_capital_employed",
        type: "Key Insights",
      },
    },
    {
      title: "Return on retained earnings",
      key: "returnOnRetainedEarnings",
      defToolTip: {
        key: "return_on_retained_earnings",
        type: "Key Insights",
      },
    },
    {
      title: "Shares Repurchase",
      key: "shareBuyBack",
      defToolTip: {
        key: "share_buybacks",
        type: "Key Insights",
      },
    },
    {
      title: "SBC as percentage of FCF",
      key: "stockBasedCompensationAsPercentageOfFCF",
      defToolTip: {
        key: "sbc_dilution",
        type: "Key Insights",
      },
    },
  ]
  let financeSpecificRows = [
    {
      title: "Return on assets (ROA)",
      key: "returnOnAssets",
      defToolTip: {
        key: "return_on_assets",
        type: "Key Insights",
      },
    },
    {
      title: "Return on equity (ROE)",
      key: "returnOnEquity",
      defToolTip: {
        key: "return_on_equity",
        type: "Key Insights",
      },
    },
    {
      title: "Return on tangible common equity (ROTCE)",
      key: "returnOnTangibleEquity",
      defToolTip: {
        key: "return_on_tangible_common_equity",
        type: "Key Insights",
      },
    },
    {
      title: "Shares Repurchase",
      key: "shareBuyBack",
      defToolTip: {
        key: "share_buybacks",
        type: "Key Insights",
      },
    },
  ]

  const [rows] = useState(
    isBankInsuranceOrREIT(
      overview?.profile?.sector,
      overview?.profile?.industry
    )
      ? financeSpecificRows
      : allRows
  )

  // To display formatted value
  const displayValue = (
    value,
    ext = "%",
    thousandSeparator = false,
    isSuffix = true
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Box className={classes["big-numbers"]}>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <Box className={classes["table-header"]}>
                  <span>
                    <div className={classes["main-header"]}>
                      Management Effectiveness
                    </div>
                    <div className={classes["sub-header"]}>
                      Based on the returns and the share repurchased by the
                      management
                    </div>
                  </span>
                  <span>
                    <Rating
                      score={keyInsights?.managementEffectiveness.score}
                    />
                    <Score score={keyInsights?.managementEffectiveness.score} />
                  </span>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Metrics</TableCell>
              <TableCell align="center">10Yr</TableCell>
              <TableCell align="center">5Yr</TableCell>
              <TableCell align="center">3Yr</TableCell>
              <TableCell align="center">TTM</TableCell>
              <TableCell align="center">Rating</TableCell>
            </TableRow>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.managementEffectiveness[row.key]?.tenYear
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.managementEffectiveness[row.key]?.fiveYear
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.managementEffectiveness[row.key]?.threeYear
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.managementEffectiveness[row.key]?.ttm
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  <Rating
                    score={keyInsights?.managementEffectiveness[row.key]?.score}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ManagementEffectiveness

ManagementEffectiveness.propTypes = {
  keyInsights: PropTypes.object.isRequired,
}
