import React from "react"
import Chip from "@mui/material/Chip"
import { palette } from "../../../utils/Palette"

const MoatChip = ({ score }) => {
  const getMoatDetails = (score) => {
    if (score >= 3.75) return { label: "WIDE", color: palette["score-5-bg"] }
    if (score >= 2.5) return { label: "NARROW", color: palette["score-3-bg"] }
    return { label: "NONE", color: palette["score-2-bg"] } // Grey for N/A
  }
  const { label, color } = getMoatDetails(score)

  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: color,
        color: "white", // Ensure text contrast
        fontSize: "0.75rem",
        fontWeight: "bold",
        borderRadius: "4px", // Reduced border radius
        width: "100px", // Fixed width to accommodate up to 5 characters
        height: "24px", // Adjust height for alignment with fixed width
        textAlign: "center", // Center text horizontally
        justifyContent: "center", // Center text inside Chip
        overflow: "hidden", // Hide text overflow
        whiteSpace: "nowrap", // Prevent text wrapping
        padding: 0, // Remove additional padding
      }}
    />
  )
}

export default MoatChip
