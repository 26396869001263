import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"

import { generatePrevYears } from "../../../../utils/financeFns"
import Header from "./components/Header"
import Row from "./components/Row"
import useStyles from "./styles"
import { getHeaders, getPercentageHeaders } from "./config/headers"
import { getForexPrice } from "../../../../services/core"
import {
  getUsdValues,
  getUsdPercentValues,
  getConversionValues,
} from "./services"
import { cloneDeep, reverse } from "lodash"
import ReactGA from "react-ga4"

const BalanceSheetStatement = ({ balanceSheetStatement }) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/financials/balance-sheet",
  })
  const { tickerId } = useParams()
  const [displayData, setDisplayData] = useState({})
  const [masterDisplayData, setMasterDisplayData] = useState({})
  const [displayDataType, setDisplayDataType] = useState("annualData")
  const [displayDataConversionType, setDisplayDataConversionType] =
    useState("raw")
  const [headers, setHeaders] = useState([])
  const [masterHeaders, setMasterHeaders] = useState([])
  const [rowHeaders, setRowHeaders] = useState([])
  const [displayCurrency, setDisplayCurrency] = useState("USD")
  const [showCurrencyConverter, setShowCurrencyConverter] = useState(false)
  const [currencyOpt, setCurrencyOpt] = useState("USD")
  const [forexPrice, setForexPrice] = useState({})
  const [flags, setFlags] = useState({
    showAssets: true,
    showCurrentAssets: true,
    showCurrentAssetsCash: true,
    showNonCurrentAssets: true,
    showNonCurrentAssetsProperty: true,
    showLiabilitiesAndSHEquity: true,
    showLiabilities: true,
    showCurrentLiabilities: true,
    showNonCurrentLiabilities: true,
    showStockHldsEquity: true,
  })
  const classes = useStyles()

  const displayDataTypeMapper = {
    annualData: "Annual",
    quarterlyData: "Quarterly",
    ttmData: "TTM",
  }

  useEffect(() => {
    document.title = `${tickerId} - Financials - Balance Sheet - Lets Value Invest`
    let incomeStatementData = balanceSheetStatement[displayDataType]
    if (!balanceSheetStatement[displayDataType]) return
    let { tempHeaders, defaultData } = getUsdValues(incomeStatementData || [])
    let { defaultPerData } = getUsdPercentValues(incomeStatementData || [])
    // check for currency and get forex price
    const checkForCurrency = async () => {
      if (balanceSheetStatement[displayDataType])
        setDisplayCurrency(
          balanceSheetStatement[displayDataType][0]?.reportedCurrency
        )
      if (
        balanceSheetStatement[displayDataType][0]?.reportedCurrency !== "USD"
      ) {
        const exchangeRateResp = await getForexPrice(
          `${balanceSheetStatement[displayDataType][0]?.reportedCurrency}USD`
        )
        setForexPrice(exchangeRateResp.data)
        setShowCurrencyConverter(true)
        setCurrencyOpt(
          balanceSheetStatement[displayDataType][0]?.reportedCurrency
        )
        incomeStatementData = JSON.parse(
          JSON.stringify(balanceSheetStatement[displayDataType])
        )
      }
    }
    // get forex price only for first render
    !forexPrice?.price && checkForCurrency()
    if (currencyOpt === "USD" && showCurrencyConverter) {
      defaultData = getConversionValues(
        incomeStatementData || [],
        forexPrice?.price
      )?.defaultData
      defaultPerData = getUsdPercentValues(
        incomeStatementData || []
      )?.defaultPerData
    }
    // generate extra headers if there are less than 10 data obj
    if (
      tempHeaders.length < 10 &&
      defaultData?.cash?.length &&
      tempHeaders[0]
    ) {
      tempHeaders = [
        ...generatePrevYears(
          10 - tempHeaders.length,
          new Date(tempHeaders[0]).getFullYear() - 1
        ).reverse(),
        ...tempHeaders,
      ]
    }
    setHeaders(tempHeaders.length ? tempHeaders : Array.apply("NA", Array(10)))
    setDisplayData(defaultData)
    setMasterDisplayData(cloneDeep(defaultData))
    setMasterHeaders(
      cloneDeep(tempHeaders.length ? tempHeaders : Array.apply("NA", Array(10)))
    )
    setRowHeaders(getHeaders(setFlags))
    if (displayDataConversionType !== "raw") {
      setDisplayData(defaultPerData)
      setMasterDisplayData(cloneDeep(defaultPerData))
      setRowHeaders(getPercentageHeaders(setFlags))
    }
  }, [
    balanceSheetStatement,
    displayDataType,
    displayDataConversionType,
    currencyOpt,
  ])

  const reverseData = () => {
    setHeaders([...reverse(headers)])
    let tempData = displayData
    for (const key in displayData) {
      tempData = { ...tempData, [key]: reverse(displayData[key]) }
    }
    setDisplayData(tempData)
  }

  return (
    <Box>
      <Header
        displayDataType={displayDataType}
        setDisplayDataType={setDisplayDataType}
        displayDataConversionType={displayDataConversionType}
        setDisplayDataConversionType={setDisplayDataConversionType}
        displayCurrency={displayCurrency}
        showCurrencyConverter={showCurrencyConverter}
        setShowCurrencyConverter={setShowCurrencyConverter}
        currencyOpt={currencyOpt}
        setCurrencyOpt={setCurrencyOpt}
        reverseData={reverseData}
      />
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 300 }} className={classes.sticky}>
                Years
              </TableCell>
              {headers.map((year, index) => (
                <TableCell key={year} align="right">
                  {year}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowHeaders.map((header) => {
              if (header.displayFlag)
                return (
                  flags[header.displayFlag] && (
                    <Row
                      key={header.key}
                      rowData={displayData[header?.key]}
                      masterRowData={masterDisplayData[header?.key]}
                      masterHeaders={masterHeaders}
                      config={header}
                      flags={flags}
                      headers={headers}
                      displayDataType={displayDataTypeMapper[displayDataType]}
                    />
                  )
                )
              return (
                <Row
                  key={header.key}
                  rowData={displayData[header?.key]}
                  config={header}
                  flags={flags}
                  headers={headers}
                  displayDataType={displayDataTypeMapper[displayDataType]}
                  masterDisplayData={masterDisplayData[header?.key]}
                  masterHeaders={masterHeaders}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default BalanceSheetStatement

BalanceSheetStatement.propTypes = {
  balanceSheetStatement: PropTypes.object.isRequired,
}
