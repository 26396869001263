import { isEmpty } from "lodash"
import { React, useEffect, useState, useContext } from "react"
import {
  Box,
  Card,
  CardContent,
  Link,
  Grid,
  Typography,
  Alert,
  Chip,
  useMediaQuery,
} from "@mui/material"
import { ArrowUpward, ArrowDownward } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import { getIndexes } from "../../services/ticker"
import { toast } from "react-toastify"
import Loading from "../Loading"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { MultiSelect } from "primereact/multiselect"
import "primereact/resources/themes/lara-light-cyan/theme.css"
import { FilterMatchMode } from "primereact/api"
import { useNavigate } from "react-router-dom"
import AppDataContext from "../../context/data"
import MetaTags from "../MetaTags"
import moment from "moment"
import Ticker from "../Chip/Ticker"
import Rating from "../Chip/Rating"
import Moat from "../Chip/Moat"
import { displayValue } from "../../utils/NumberFormatters"

import useStyles from "./styles"
import SectorWeightage from "./SectorWeightage"
import ReactGA from "react-ga4"

const Constituents = () => {
  const classes = useStyles()
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"))
  const { type } = useParams()
  const [indexOpt, setIndexOpt] = useState(1)
  const [stockData, setStockData] = useState([])
  const [weightageOfTopStocks, setWeightageOfTopStocks] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [showPaginator, setShowPaginator] = useState(false)
  const navigate = useNavigate()
  const { indexData, setIndexData } = useContext(AppDataContext)
  const [sectors, setSectors] = useState([])
  const [filters, setFilters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    symbol: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sector: { value: null, matchMode: FilterMatchMode.IN },
  })

  // for meta tags
  let title = "S&P 500 Index - Lets Value Invest"
  let description = "All Stocks Listed on the S&P 500 Index"
  let page = "/dashboard/indexes/sp500"
  if (type === "nasdaq") {
    title = "NASDAQ 100 Index - Lets Value Invest"
    description = "All Stocks Listed on the NASDAQ 500 Index"
    page = "/dashboard/indexes/nasdaq"
  }
  if (type === "dowjones") {
    title = "Dow Jones 30 Index - Lets Value Invest"
    description = "All Stocks Listed on the Dow Jones 30 Index"
    page = "/dashboard/indexes/dowjones"
  }

  ReactGA.send({
    hitType: "pageview",
    page: { page },
  })
  const imageUrl = "https://letsvalueinvest.com/Assets/logo.png"

  const getWeightageOfTopStocks = (noOfStocks, elements) => {
    let totalWeightageOfTopStocks = 0
    if (isEmpty(elements)) {
      return totalWeightageOfTopStocks
    }
    for (let i = 0; i < noOfStocks; i += 1) {
      totalWeightageOfTopStocks += elements[i].weightage
    }
    return totalWeightageOfTopStocks
  }

  const getUniqueSectors = (elements) => {
    const uniqueSectors = elements?.reduce((acc, stock) => {
      if (stock?.sector && !acc.includes(stock?.sector)) {
        acc.push(stock?.sector)
      }
      return acc
    }, [])
    return uniqueSectors
  }

  useEffect(() => {
    setIsLoading(true)
    const setDisplayData = (indexData) => {
      if (type === "sp500") {
        setStockData(indexData.sp500)
        setShowPaginator(true)
        setIndexOpt(1)
        const options = getUniqueSectors(indexData.sp500.elements)
        setSectors(options)
        setWeightageOfTopStocks(
          getWeightageOfTopStocks(10, indexData.sp500.elements)
        )
      }
      if (type === "nasdaq") {
        setShowPaginator(true)
        setStockData(indexData.nasdaq)
        setIndexOpt(2)
        setSectors(getUniqueSectors(indexData.nasdaq.elements))
        setWeightageOfTopStocks(
          getWeightageOfTopStocks(10, indexData.nasdaq.elements)
        )
      }
      if (type === "dowjones") {
        setStockData(indexData.dowjones)
        setShowPaginator(false)
        setIndexOpt(3)
        setSectors(getUniqueSectors(indexData.dowjones.elements))
        setWeightageOfTopStocks(
          getWeightageOfTopStocks(10, indexData.dowjones.elements)
        )
      }
      if (type === "russell2000") {
        setStockData(indexData.russell2000)
        setShowPaginator(true)
        setIndexOpt(4)
        setSectors(getUniqueSectors(indexData.russell2000.elements))
        setWeightageOfTopStocks(
          getWeightageOfTopStocks(10, indexData.russell2000.elements)
        )
      }
    }
    const getData = async () => {
      toast.dismiss()
      try {
        const resp = await getIndexes()
        setIndexData(resp.data)
        setDisplayData(resp.data)
        setIsLoading(false)
      } catch (err) {
        setStockData([])
        setIsLoading(false)
        toast.error("Unable to get index data. Please try after sometime.")
      }
    }
    if (indexData?.sp500) {
      setDisplayData(indexData)
      setIsLoading(false)
      return
    }
    getData()
  }, [type, indexData, setIndexData, setSectors])

  const currencyBodyTemplate = (stock) => {
    return displayValue(stock.price)
  }

  const changeBodyTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChange} ${
          stock?.change < 0 ? "error-txt" : "success-txt"
        }`}
      >
        {displayValue(stock.change)}
      </Typography>
    )
  }

  const percentageChangeBodyTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock?.changesPercentage < 0 ? "error-txt" : "success-txt"
        }`}
      >
        {displayValue(stock.changesPercentage, "%", true, true)}
      </Typography>
    )
  }

  const changeFromPriceAvg50BodyTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock.percentChangeFromPriceAvg50 < 0 ? "error-txt" : "success-txt"
        }`}
      >
        {displayValue(stock.percentChangeFromPriceAvg50, "%", true, true)}
      </Typography>
    )
  }

  const changeFromPriceAvg200BodyTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock.percentChangeFromPriceAvg200 < 0 ? "error-txt" : "success-txt"
        }`}
      >
        {displayValue(stock.percentChangeFromPriceAvg200, "%", true, true)}
      </Typography>
    )
  }

  const priceYearHighBodyTemplate = (stock) => {
    return displayValue(stock.yearHigh)
  }

  const percentChangeFromYearHighTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock.percentChangeFromYearHigh < 0 ? "error-txt" : "success-txt"
        }`}
      >
        {displayValue(stock.percentChangeFromYearHigh, "%", true, true)}
      </Typography>
    )
  }

  const priceYearLowBodyTemplate = (stock) => {
    return displayValue(stock.yearLow)
  }

  const percentChangeFromYearLowTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock.percentChangeFromYearLow < 0 ? "error-txt" : "success-txt"
        }`}
      >
        {displayValue(stock.percentChangeFromYearLow, "%", true, true)}
      </Typography>
    )
  }

  const sectorBodyTemplate = (rowData) => {
    const sector = rowData.sector
    return (
      <div className="flex align-items-center gap-2">
        <span>{sector}</span>
      </div>
    )
  }

  const sectorsItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option}</span>
      </div>
    )
  }

  const sectorRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={sectors}
        itemTemplate={sectorsItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    )
  }

  const priceAvg50BodyTemplate = (stock) => {
    return displayValue(stock.priceAvg50)
  }

  const priceAvg200BodyTemplate = (stock) => {
    return displayValue(stock.priceAvg200)
  }

  const marketCapBodyTemplate = (stock) => {
    return displayValue(stock.marketCap)
  }

  const symbolBodyTemplate = (stock) => {
    return (
      <Box sx={{ textAlign: "left" }}>
        <Link
          onClick={() => {
            navigate(`/dashboard/ticker/${stock.symbol}/overview`)
          }}
          className={classes["custom-link"]}
        >
          <Ticker ticker={stock?.symbol} />
        </Link>
      </Box>
    )
  }

  const onIndexTemplate = (data, props) => {
    return props.rowIndex + 1
  }

  const weightageBodyTemplate = (stock) => {
    return stock.weightage + "%"
  }

  const header = () => {
    return (
      <Typography className={classes.momentAgo} variant="body1">
        This data was generated {moment(indexData?.createdAt).fromNow()} . While
        not real-time, this data is updated every 24 hours.
      </Typography>
    )
  }

  const ratingBodyTemplate = (stock) => {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Rating score={stock?.keyInsightsData?.overallScore} />
      </Box>
    )
  }
  const ytdReturnsBodyTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock?.keyInsightsData?.pastReturns?.ytd < 0
            ? "error-txt"
            : "success-txt"
        }`}
      >
        {displayValue(
          stock?.keyInsightsData?.pastReturns?.ytd,
          "%",
          true,
          true
        )}
      </Typography>
    )
  }

  const oneYearReturnsBodyTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock?.keyInsightsData?.pastReturns?.["1Y"] < 0
            ? "error-txt"
            : "success-txt"
        }`}
      >
        {displayValue(
          stock?.keyInsightsData?.pastReturns?.["1Y"],
          "%",
          true,
          true
        )}
      </Typography>
    )
  }

  const threeYearReturnsBodyTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock?.keyInsightsData?.pastReturns?.["3Y"] < 0
            ? "error-txt"
            : "success-txt"
        }`}
      >
        {displayValue(
          stock?.keyInsightsData?.pastReturns?.["3Y"],
          "%",
          true,
          true
        )}
      </Typography>
    )
  }

  const fiveYearReturnsBodyTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock?.keyInsightsData?.pastReturns?.["5Y"] < 0
            ? "error-txt"
            : "success-txt"
        }`}
      >
        {displayValue(
          stock?.keyInsightsData?.pastReturns?.["5Y"],
          "%",
          true,
          true
        )}
      </Typography>
    )
  }

  const tenYearReturnsBodyTemplate = (stock) => {
    return (
      <Typography
        className={`${classes.priceChangePer} ${
          stock?.keyInsightsData?.pastReturns?.["10Y"] < 0
            ? "error-txt"
            : "success-txt"
        }`}
      >
        {displayValue(
          stock?.keyInsightsData?.pastReturns?.["10Y"],
          "%",
          true,
          true
        )}
      </Typography>
    )
  }

  const moatBodyTemplate = (stock) => {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Moat score={stock?.keyInsightsData?.moat?.score} />
      </Box>
    )
  }

  const valuationBodyTemplate = (stock) => {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Rating score={stock?.keyInsightsData?.valuationMultiples?.score} />
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }
  if (isEmpty(stockData.elements)) {
    return (
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Alert severity="warning" sx={{ mt: 5, ml: 5, fontSize: 16 }}>
            Unfortunately the data for <strong>{stockData?.name}</strong> is not
            available yet. Please check again later.
          </Alert>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction="row" className={classes.LVIDataTable}>
      <MetaTags
        title={title}
        description={description}
        url={window.location.href}
        imageUrl={imageUrl}
      ></MetaTags>
      <Grid container spacing={2} item xs={12} sm={6} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            All Stocks Listed on the {stockData?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {stockData?.name}
              </Typography>
              <Box display="flex" flexDirection="row">
                <Typography className={classes.price}>
                  {stockData?.price}
                </Typography>
                <Typography
                  className={`${classes.priceChangePer} ${
                    stockData?.changesPercentage < 0
                      ? "percentage-down-txt"
                      : "percentage-up-txt"
                  }`}
                >
                  {stockData?.changesPercentage > 0 ? (
                    <ArrowUpward
                      sx={{ fontSize: 20 }}
                      className={classes.icon}
                    />
                  ) : (
                    <ArrowDownward
                      sx={{ fontSize: 20 }}
                      className={classes.icon}
                    />
                  )}
                  {stockData?.changesPercentage > 0 ? "+" : ""}
                  {displayValue(
                    stockData?.changesPercentage,
                    "%",
                    true,
                    true,
                    true
                  )}
                </Typography>
                <Typography
                  className={`${classes.priceChange} ${
                    stockData?.change < 0 ? "error-txt" : "success-txt"
                  }`}
                >
                  {stockData?.change > 0
                    ? `+${stockData?.change?.toFixed(2)}`
                    : stockData?.change?.toFixed(2)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Grid item xs={12} sm={4}>
            <Box
              display={largeScreen ? "flex" : ""}
              className={classes["index-opts"]}
            >
              <Chip
                className={`${classes["opt-label"]} ${
                  indexOpt === 1 && classes["active-opt"]
                }`}
                label="S&P 500"
                onClick={() => navigate(`/dashboard/indexes/sp500`)}
              />
              <Chip
                className={`${classes["opt-label"]} ${
                  indexOpt === 2 && classes["active-opt"]
                }`}
                label="Nasdaq 100"
                onClick={() => navigate(`/dashboard/indexes/nasdaq`)}
              />
              <Chip
                className={`${classes["opt-label"]} ${
                  indexOpt === 3 && classes["active-opt"]
                }`}
                label="Dow Jones 30"
                onClick={() => navigate(`/dashboard/indexes/dowjones`)}
              />
              <Chip
                className={`${classes["opt-label"]} ${
                  indexOpt === 4 && classes["active-opt"]
                }`}
                label="Russell 2000"
                onClick={() => navigate(`/dashboard/indexes/russell2000`)}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                # of stocks
              </Typography>
              <Typography variant="h5" component="div">
                {stockData?.elements?.length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Total Market Cap
              </Typography>
              <Typography variant="h5" component="div">
                {displayValue(stockData?.totalMarketCap)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Weightage of top 10
              </Typography>
              <Typography variant="h5" component="div">
                {displayValue(weightageOfTopStocks, "%", true, true)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12} sm={6} sx={{ mb: 3 }}>
        <Box
          width="100%"
          height="100%"
          display={"flex"}
          justifyContent="center"
        >
          <SectorWeightage sectorWeightages={stockData?.sectorWeightages} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {/* Use DataGrid or List component */}
        <DataTable
          value={stockData.elements}
          size="small"
          header={header}
          showGridlines
          stripedRows
          paginator={showPaginator}
          rows={25}
          rowsPerPageOptions={[25, 50, 100]}
          sortField="marketCap"
          sortOrder={-1}
          sortMode="multiple"
          removableSort
          filters={filters}
          filterDisplay="row"
          emptyMessage="No stock found."
          scrollable
          scrollHeight="800px"
          reorderableColumns
        >
          <Column field="Index" header="" body={onIndexTemplate} />
          <Column
            field="symbol"
            header="Symbol"
            body={symbolBodyTemplate}
          ></Column>
          <Column
            field="name"
            filter
            filterPlaceholder="Search by name"
            header="Name"
            style={{ minWidth: "20rem" }}
          ></Column>
          <Column
            field="keyInsightsData.overallScore"
            header="RATING"
            sortable
            body={ratingBodyTemplate}
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="keyInsightsData.moat.score"
            header="MOAT"
            sortable
            body={moatBodyTemplate}
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="keyInsightsData.valuationMultiples.score"
            header="Valuation"
            sortable
            body={valuationBodyTemplate}
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="marketCap"
            sortable
            header="Market Cap"
            body={marketCapBodyTemplate}
          ></Column>
          <Column
            field="sector"
            header="Sector"
            filterField="sector"
            showFilterMenu={false}
            filterMenuStyle={{ width: "14rem" }}
            style={{ minWidth: "14rem" }}
            body={sectorBodyTemplate}
            filter
            filterElement={sectorRowFilterTemplate}
          ></Column>
          <Column
            field="weightage"
            header="Weightage"
            sortable
            body={weightageBodyTemplate}
          ></Column>
          <Column
            field="price"
            header="Current Price"
            body={currencyBodyTemplate}
          ></Column>
          <Column
            field="change"
            header="Change"
            sortable
            body={changeBodyTemplate}
          ></Column>
          <Column
            field="changesPercentage"
            header="% Change"
            sortable
            body={percentageChangeBodyTemplate}
          ></Column>
          <Column
            field="priceAvg50"
            header="50 DMA"
            body={priceAvg50BodyTemplate}
          ></Column>
          <Column
            field="percentChangeFromPriceAvg50"
            header="% chg from 50 DMA"
            sortable
            body={changeFromPriceAvg50BodyTemplate}
          ></Column>
          <Column
            field="priceAvg200"
            header="200 DMA"
            body={priceAvg200BodyTemplate}
          ></Column>
          <Column
            field="percentChangeFromPriceAvg200"
            header="% chg from 200 DMA"
            sortable
            body={changeFromPriceAvg200BodyTemplate}
          ></Column>
          <Column
            field="yearHigh"
            header="52 Week High"
            body={priceYearHighBodyTemplate}
          ></Column>
          <Column
            field="percentChangeFromYearHigh"
            header="% chg from 52 Week High"
            sortable
            body={percentChangeFromYearHighTemplate}
          ></Column>
          <Column
            field="yearLow"
            header="52 Week Low"
            body={priceYearLowBodyTemplate}
          ></Column>
          <Column
            field="percentChangeFromYearLow"
            header="% chg from 52 Week Low"
            sortable
            body={percentChangeFromYearLowTemplate}
          ></Column>
          <Column
            field="keyInsightsData.pastReturns.ytd"
            header="YTD returns"
            sortable
            body={ytdReturnsBodyTemplate}
          ></Column>
          <Column
            field="keyInsightsData.pastReturns.1Y"
            header="1 year returns"
            sortable
            body={oneYearReturnsBodyTemplate}
          ></Column>
          <Column
            field="keyInsightsData.pastReturns.3Y"
            header="3 year returns"
            sortable
            body={threeYearReturnsBodyTemplate}
          ></Column>
          <Column
            field="keyInsightsData.pastReturns.5Y"
            header="5 year returns"
            sortable
            body={fiveYearReturnsBodyTemplate}
          ></Column>
          <Column
            field="keyInsightsData.pastReturns.10Y"
            header="10 year returns"
            sortable
            body={tenYearReturnsBodyTemplate}
          ></Column>
        </DataTable>
      </Grid>
      {/* Or use List component */}
      {/* <List>
        {stockData.map((stock) => (
          <ListItem key={stock.symbol}>
            {stock.name} ({stock.symbol}) - ${stock.marketCap}
          </ListItem>
        ))}
      </List> */}
    </Grid>
  )
}

export default Constituents
