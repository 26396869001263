import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "40px 32px",
    backgroundColor: theme.palette["grey-1"],
  },
  "footer-text": {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    color: theme.palette.white,
    fontWeight: "500 !important",
    fontSize: "14px !important",
  },
  "footer-icons": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.white,
    fontSize: "20px !important",
    cursor: "pointer",
  },
  version: {
    marginLeft: "5px",
    textDecoration: "underline",
    fontStyle: "italic",
    textAlign: "center",
    color: theme.palette.white,
    fontWeight: "400 !important",
    fontSize: "14px !important",
  },
}))
