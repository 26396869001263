import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { cloneDeep } from "lodash"
import { useParams } from "react-router-dom"
import { generatePrevYears } from "../../../../../utils/financeFns"
import Header from "../Charts/Header"
import { getForexPrice } from "../../../../../services/core"
import {
  getUsdValues,
  getUsdPercentValues,
  getConversionValues,
} from "../../../Financials/IncomeStatement/services"
import Line from "../Charts/SingleItemChart"

import useStyles from "../../styles"

const GrossProfit = ({ incomeStatement }) => {
  const { tickerId } = useParams()
  const [config] = useState({
    title: "Gross Profit",
    key: "grossProfit",
    suffix: "",
    isNumber: true,
    displayNegative: true,
  })
  const [displayData, setDisplayData] = useState({})
  const [masterDisplayData, setMasterDisplayData] = useState({})
  const [masterHeaders, setMasterHeaders] = useState([])
  const [displayDataType, setDisplayDataType] = useState("annualData")
  const [displayDataConversionType, setDisplayDataConversionType] =
    useState("raw")
  const [headers, setHeaders] = useState([])
  const [displayCurrency, setDisplayCurrency] = useState("USD")
  const [showCurrencyConverter, setShowCurrencyConverter] = useState(false)
  const [currencyOpt, setCurrencyOpt] = useState("USD")
  const [forexPrice, setForexPrice] = useState({})
  /* const [flags, setFlags] = useState({
    showOperatingExpenses: false,
    showEarningsPerShare: false,
  }) */
  const classes = useStyles()

  const displayDataTypeMapper = {
    annualData: "Annual",
    quarterlyData: "Quarterly",
    ttmData: "TTM",
  }

  useEffect(() => {
    // document.title = `${tickerId} - Financials - Income Statement - Lets Value Invest`
    let incomeStatementData = incomeStatement[displayDataType]
    if (!incomeStatement[displayDataType]) return
    let { tempHeaders, defaultData } = getUsdValues(incomeStatementData || [])
    let { defaultPerData } = getUsdPercentValues(incomeStatementData || [])
    // check for currency and get forex price
    const checkForCurrency = async () => {
      if (incomeStatement[displayDataType])
        setDisplayCurrency(
          incomeStatement[displayDataType][0]?.reportedCurrency
        )
      if (incomeStatement[displayDataType][0]?.reportedCurrency !== "USD") {
        const exchangeRateResp = await getForexPrice(
          `${incomeStatement[displayDataType][0]?.reportedCurrency}USD`
        )
        setForexPrice(exchangeRateResp.data)
        setShowCurrencyConverter(true)
        setCurrencyOpt(incomeStatement[displayDataType][0]?.reportedCurrency)
        incomeStatementData = JSON.parse(
          JSON.stringify(incomeStatement[displayDataType])
        )
      }
    }
    // get forex price only for first render
    !forexPrice?.price && checkForCurrency()
    if (currencyOpt === "USD" && showCurrencyConverter) {
      defaultData = getConversionValues(
        incomeStatementData || [],
        forexPrice?.price
      )?.defaultData
      defaultPerData = getUsdPercentValues(
        incomeStatementData || []
      )?.defaultPerData
    }
    // generate extra headers if there are less than 10 data obj
    if (
      tempHeaders.length < 10 &&
      defaultData.grossProfit.length &&
      tempHeaders[0]
    ) {
      tempHeaders = [
        ...generatePrevYears(
          10 - tempHeaders.length,
          new Date(tempHeaders[0]).getFullYear() - 1
        ).reverse(),
        ...tempHeaders,
      ]
    }
    setHeaders(tempHeaders.length ? tempHeaders : Array.apply("NA", Array(10)))
    setDisplayData(defaultData)
    // setRowHeaders(getHeaders(setFlags))
    setMasterDisplayData(cloneDeep(defaultData))
    setMasterHeaders(
      cloneDeep(tempHeaders.length ? tempHeaders : Array.apply("NA", Array(10)))
    )
    if (displayDataConversionType !== "raw") {
      setDisplayData(defaultPerData)
      // setRowHeaders(getPercentageHeaders(setFlags))
      setMasterDisplayData(cloneDeep(defaultPerData))
    }
  }, [incomeStatement, displayDataType, displayDataConversionType, currencyOpt])

  return (
    <Box className={classes["big-numbers"]}>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <Header
                displayDataType={displayDataType}
                setDisplayDataType={setDisplayDataType}
                displayDataConversionType={displayDataConversionType}
                setDisplayDataConversionType={setDisplayDataConversionType}
                displayCurrency={displayCurrency}
                showCurrencyConverter={showCurrencyConverter}
                setShowCurrencyConverter={setShowCurrencyConverter}
                currencyOpt={currencyOpt}
                setCurrencyOpt={setCurrencyOpt}
                // reverseData={reverseData}
              />
            </TableRow>
            {/* <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>Gross Profit</div>
                <div className={classes["sub-header"]}>
                  Displays how the Gross Profit has trended for {tickerId} over
                  the years
                </div>
              </TableCell>
            </TableRow> */}
          </TableHead>
          <TableBody>
            <Box className={classes["tooltip-chart"]}>
              <Line
                dataSet={masterDisplayData["grossProfit"]}
                labels={masterHeaders}
                config={{
                  title: config.title,
                  tickerId: tickerId,
                  displayDataType: displayDataTypeMapper[displayDataType],
                  suffix: config.suffix,
                  isNumber: config.isNumber,
                  displayDataConversionType: displayDataConversionType,
                }}
              />
            </Box>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default GrossProfit

GrossProfit.propTypes = {
  incomeStatement: PropTypes.object.isRequired,
}
