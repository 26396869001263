import React from "react"
import { Typography, Grid, useMediaQuery, Stack, Link } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { CopyrightOutlined, X, YouTube } from "@mui/icons-material"
import Cookies from "js-cookie"

import useStyles from "./styles"
import { styled } from "@mui/material/styles"
import clsx from "clsx"
const StyledLogo = styled("img")(({ theme }) => ({
  height: 35, // Adjust height as needed for your logo
  marginLeft: theme.spacing(1), // Add margin between logo and text
  marginTop: theme.spacing(-2), // Add margin between logo and text
}))

const Footer = () => {
  const classes = useStyles()
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"))
  const navigate = useNavigate()

  return (
    <Grid container direction="row" className={classes.footer}>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        display="flex"
        flexDirection="row"
        justifyContent={largeScreen ? "start" : "center"}
      >
        <Typography
          className={classes["footer-text"]}
          style={{ paddingBottom: largeScreen ? "0px" : "16px" }}
        >
          <CopyrightOutlined style={{ marginRight: "8px" }} />{" "}
          {new Date().getFullYear()} Copyright. All rights reserved.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        display="flex"
        flexDirection="row"
        justifyContent={largeScreen ? "start" : "center"}
      >
        <Stack direction="row" spacing={0} alignItems="center">
          <Typography
            variant="body2"
            className={classes["footer-text"]}
            style={{ paddingBottom: largeScreen ? "0px" : "16px" }}
          >
            Supported by
          </Typography>
          <Link href="https://openqueue.us/" target="_blank" underline="none">
            <StyledLogo
              src={`${window.location.origin}/Assets/400PngdpiLogoCropped.png`}
              alt="Open Queue"
            />
          </Link>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={largeScreen ? "row" : "column"}
      >
        <Typography
          className={clsx(classes["footer-text"], "cursor-pointer")}
          style={{ marginRight: largeScreen ? "20px" : "0px" }}
        >
          <span onClick={() => navigate("/dashboard/guides/disclaimer")}>
            Disclaimer
          </span>
        </Typography>
        <Typography
          className={clsx(classes["footer-text"], "cursor-pointer")}
          style={{ marginRight: largeScreen ? "20px" : "0px" }}
        >
          <span onClick={() => navigate("/dashboard/guides/privacy-policy")}>
            Privacy policy
          </span>
        </Typography>
        <Typography
          className={clsx(classes["footer-text"], "cursor-pointer")}
          style={{ marginRight: largeScreen ? "20px" : "0px" }}
        >
          <span onClick={() => navigate("/dashboard/guides/terms-of-service")}>
            Terms of Service
          </span>
        </Typography>
        {/* <Typography
          className={clsx(classes["footer-text"], "cursor-pointer")}
          style={{ marginRight: largeScreen ? "20px" : "0px" }}
        >
          Limitations & Caveats
        </Typography> */}
        <Typography
          className={clsx(classes["footer-text"], "cursor-pointer")}
          style={{
            marginRight: largeScreen ? "20px" : "0px",
            paddingBottom: largeScreen ? "0px" : "16px",
          }}
        >
          <span
            onClick={() => window.open("mailto:support@letsvalueinvest.com")}
          >
            Contact Us
          </span>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        display="flex"
        justifyContent={largeScreen ? "end" : "center"}
        alignItems="center"
        flexDirection="row"
      >
        <Typography className={classes["footer-icons"]}>
          {/* <Facebook style={{ marginRight: "16px", fontSize: "28px" }} />*/}
          <X
            style={{ marginRight: "16px", fontSize: "25px" }}
            onClick={() => window.open("https://x.com/letsvalueinvest")}
          />
          <YouTube
            style={{ marginRight: "16px", fontSize: "35px" }}
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCj__Yox6lLRZySg7noJsj0w"
              )
            }
          />
          <Link
            href="https://www.producthunt.com/posts/lets-value-invest?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-lets&#0045;value&#0045;invest"
            target="_blank"
            underline="none"
            rel="noreferrer"
          >
            <StyledLogo
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=557875&theme=light"
              alt="Lets&#0032;Value&#0032;Invest - Learn&#0044;&#0032;Analyze&#0044;&#0032;Invest&#0058;&#0032;Your&#0032;Value&#0032;Investing&#0032;Toolkit | Product Hunt"
              style={{
                width: "250px",
                height: "40px",
                marginTop: "0px",
                marginLeft: "0px",
              }}
            />
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography className={classes["version"]}>
          version: {Cookies.get("lvi_version")}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Footer
