import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../../../styles"
import { TextThousandSeparator } from "../../../../../../utils/NumberFormatters"
import { isBankInsuranceOrREIT } from "../../../../../../utils/KeyInsightsScores"
import Rating from "../../../../../Chip/Rating"
import Score from "../../../../../Chip/Score"
import Term from "../../../../../../utils/Term"

const BusinessQuality = ({ keyInsights, overview }) => {
  const classes = useStyles()
  const allRows = [
    {
      title: "Gross margin",
      key: "grossProfitMargin",
      defToolTip: {
        key: "gross_margin",
        type: "Key Insights",
      },
    },
    {
      title: "Return on capital employed (ROCE)",
      key: "returnOnCapitalEmployed",
      defToolTip: {
        key: "return_on_capital_employed",
        type: "Key Insights",
      },
    },
    {
      title: "Return on inremental capital employed",
      key: "returnOnIncrementalCapitalEmployed",
      defToolTip: {
        key: "return_on_incremental_capital_employed",
        type: "Key Insights",
      },
    },
    {
      title: "Debt to Op cash flow",
      key: "debtToCashflowRatio",
      ext: "x",
      defToolTip: {
        key: "debt_to_ocf_ratio",
        type: "Key Insights",
      },
    },
    {
      title: "Reinvestment Rate",
      key: "reinvestmentRate",
      defToolTip: {
        key: "reinvestment_rate",
        type: "Key Insights",
      },
    },
  ]
  const financeSpecificRows = []
  const [rows] = useState(
    isBankInsuranceOrREIT(
      overview?.profile?.sector,
      overview?.profile?.industry
    )
      ? financeSpecificRows
      : allRows
  )

  // To display formatted value
  const displayValue = (
    value,
    ext = "%",
    thousandSeparator = false,
    isSuffix = true
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Box className={classes["big-numbers"]}>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <Box className={classes["table-header"]}>
                  <span>
                    <div className={classes["main-header"]}>
                      Quality of Business{" "}
                      <sup className={`${classes["super-text"]}`}>NEW</sup>
                    </div>
                    <div className={classes["sub-header"]}>
                      Measures the quality of the business
                    </div>
                  </span>
                  <span>
                    <Rating score={keyInsights?.businessQuality.score} />
                    <Score score={keyInsights?.businessQuality.score} />
                  </span>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Metrics</TableCell>
              <TableCell align="center">10Yr</TableCell>
              <TableCell align="center">5Yr</TableCell>
              <TableCell align="center">3Yr</TableCell>
              <TableCell align="center">TTM</TableCell>
              <TableCell align="center">Rating</TableCell>
            </TableRow>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.businessQuality[row.key]?.tenYear,
                    row?.ext
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.businessQuality[row.key]?.fiveYear,
                    row?.ext
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.businessQuality[row.key]?.threeYear,
                    row?.ext
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(
                    keyInsights?.businessQuality[row.key]?.ttm,
                    row?.ext
                  )}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  <Rating
                    score={keyInsights?.businessQuality[row.key]?.score}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default BusinessQuality

BusinessQuality.propTypes = {
  keyInsights: PropTypes.object.isRequired,
}
