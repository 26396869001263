import axios from "axios"

const baseURL = `${process.env.REACT_APP_BASE_API}`

export const getWatchlists = async (userId) => {
  return axios.get(`${baseURL}/api/v1/watchlists/${userId}`).catch((err) => {
    throw new Error(err)
  })
}

export const createWatchlist = async (reqBody) => {
  return axios
    .post(`${baseURL}/api/v1/watchlists`, reqBody, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export const getFavoriteStatusAndCount = async (userId, tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/watchlists/${userId}/${tickerId}/favorite-status`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const removeTicker = async (userId, watchlistId, tickerId) => {
  return axios
    .delete(
      `${baseURL}/api/v1/watchlists/${userId}/${watchlistId}/stocks/${tickerId}`
    )
    .catch((err) => {
      throw new Error(err)
    })
}

export const addOrUpdateTicker = async (
  userId,
  watchlistId,
  tickerId,
  reqBody
) => {
  return axios
    .post(
      `${baseURL}/api/v1/watchlists/${userId}/${watchlistId}/stocks/${tickerId}`,
      reqBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .catch((err) => {
      throw new Error(err)
    })
}
