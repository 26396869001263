import React, { useContext, useEffect } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { useParams } from "react-router-dom"

import useStyles from "./styles"
import AppDataContext from "../../../context/data"
import BigNumbers from "./components/BigNumbers"
import ShareStatistics from "./components/ShareStatistics"
import PerShareNumbers from "./components/PerShareNumbers"
import MarginsProfitability from "./components/MarginsProfitability"
import FinancialHealth from "./components/FinancialHealth"
import ManagementEffectiveness from "./components/ManagementEffectiveness"
import CurrentYields from "./components/CurrentYields"
import GrowthRates from "./components/GrowthRates"
import PriceMultiples from "./components/PriceMultiples"
import EnterpriseValueMultiples from "./components/EnterpriseValueMultiples"
import HistoricalPriceChart from "./components/HistoricalPriceChart"
import ReactGA from "react-ga4"
import Profile from "./components/Profile"
import News from "./components/News"

const Overview = () => {
  ReactGA.send({ hitType: "pageview", page: "/ticker/overview" })
  const { overview } = useContext(AppDataContext)
  const { tickerId } = useParams()

  const classes = useStyles()

  useEffect(() => {
    document.title = `${tickerId} - Overview - Lets Value Invest`
  }, [])

  return (
    <Box className={classes["ticker-container"]}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} sm={3}>
          <BigNumbers data={overview?.bigNumbers || {}} />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={12} sm={10}>
              <HistoricalPriceChart
                historicalPriceFull={
                  overview?.historicalPriceFull?.reverse() || []
                }
                historicalPrice5Min={
                  overview?.historicalPrice5Min?.reverse() || []
                }
                overview={overview || {}}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12} sm={4}>
              <ShareStatistics data={overview?.shareStats || {}} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PerShareNumbers data={overview?.perShareNumbers || {}} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MarginsProfitability data={overview?.margins || {}} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FinancialHealth data={overview?.financialHealth || {}} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ManagementEffectiveness
                data={overview?.managementEffectiveness || {}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrentYields data={overview?.currentYields || {}} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <GrowthRates data={overview?.growthRates || {}} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PriceMultiples data={overview?.valuationMultiples || {}} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <EnterpriseValueMultiples
                data={overview?.enterpriseValueMultiples || {}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Profile data={overview?.profile || {}} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <News name={overview?.news?.name} data={overview?.news?.data || []} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Overview
