import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../../../styles"
import { TextThousandSeparator } from "../../../../../../utils/NumberFormatters"
import { isBankInsuranceOrREIT } from "../../../../../../utils/KeyInsightsScores"
import Rating from "../../../../../Chip/Rating"
import Score from "../../../../../Chip/Score"
import Term from "../../../../../../utils/Term"

const GrowthRates = ({ keyInsights, overview }) => {
  const classes = useStyles()
  let allRows = [
    {
      title: "Revenue",
      key: "revenue",
      defToolTip: {
        key: "revenue",
        type: "Income statement",
      },
    },
    {
      title: "Operating Income",
      key: "operatingIncome",
      defToolTip: {
        key: "operating_income",
        type: "Income statement",
      },
    },
    {
      title: "Net Income",
      key: "netIncome",
      defToolTip: {
        key: "net_income",
        type: "Income statement",
      },
    },
    {
      title: "Earning per share (EPS)",
      key: "eps",
      defToolTip: {
        key: "earning_per_share",
        type: "Income statement",
      },
    },
    {
      title: "Book Value per share (BVPS)",
      key: "bvps",
      defToolTip: {
        key: "book_value_per_share",
        type: "Balance sheet",
      },
    },
    {
      title: "Operating Cash Flow",
      key: "operatingCashFlow",
      defToolTip: {
        key: "operating_cash_flow",
        type: "Cash flow statement",
      },
    },
    {
      title: "Free Cash Flow",
      key: "freeCashFlow",
      defToolTip: {
        key: "free_cash_flow",
        type: "Cash flow statement",
      },
    },
  ]

  let financeSpecificRows = [
    {
      title: "Revenue",
      key: "revenue",
      defToolTip: {
        key: "revenue",
        type: "Income statement",
      },
    },
    {
      title: "Net Income",
      key: "netIncome",
      defToolTip: {
        key: "net_income",
        type: "Income statement",
      },
    },
    {
      title: "Earning per share (EPS)",
      key: "eps",
      defToolTip: {
        key: "earning_per_share",
        type: "Income statement",
      },
    },
    {
      title: "Book Value per share (BVPS)",
      key: "bvps",
      defToolTip: {
        key: "book_value_per_share",
        type: "Balance sheet",
      },
    },
  ]
  const [rows] = useState(
    isBankInsuranceOrREIT(
      overview?.profile?.sector,
      overview?.profile?.industry
    )
      ? financeSpecificRows
      : allRows
  )

  // To display formatted value
  const displayValue = (
    value,
    ext = "%",
    thousandSeparator = false,
    isSuffix = true
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Box className={classes["big-numbers"]}>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <Box className={classes["table-header"]}>
                  <span>
                    <div className={classes["main-header"]}>Growth Rates</div>
                    <div className={classes["sub-header"]}>
                      Reflects how the company has grown in the past
                    </div>
                  </span>
                  <span>
                    <Rating score={keyInsights?.growthRates.score} />
                    <Score score={keyInsights?.growthRates.score} />
                  </span>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Metrics</TableCell>
              <TableCell align="center">10Yr</TableCell>
              <TableCell align="center">5Yr</TableCell>
              <TableCell align="center">3Yr</TableCell>
              <TableCell align="center">TTM</TableCell>
              <TableCell align="center">Rating</TableCell>
            </TableRow>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}{" "}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(keyInsights?.growthRates[row.key]?.tenYear)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(keyInsights?.growthRates[row.key]?.fiveYear)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(keyInsights?.growthRates[row.key]?.threeYear)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(keyInsights?.growthRates[row.key]?.ttm)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  <Rating score={keyInsights?.growthRates[row.key]?.score} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default GrowthRates

GrowthRates.propTypes = {
  keyInsights: PropTypes.object.isRequired,
}
